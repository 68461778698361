import { Alarm } from '@mui/icons-material';
import React from 'react'
import Album from './Album';

function House2() {

    const imagesHouse2 = [
        'src/assets/house2/front.jpeg',
        'src/assets/house2/center.jpeg',
        'src/assets/house2/balcony.jpeg',
        'src/assets/house2/kitchen.jpeg',
        'src/assets/house2/kitchen2.jpeg',
        'src/assets/house2/livingspace.jpeg',
        'src/assets/house2/hall.jpeg',
        'src/assets/house2/room.jpeg',
        'src/assets/house2/bathroom.jpeg',
        'src/assets/house2/tub.jpeg',
        'src/assets/house2/back.jpeg',
        'src/assets/house2/outside.jpeg',
        'src/assets/house2/nature.jpeg'
      ];

  return (
    <div>
        <Album images={imagesHouse2} />
    </div>
  )
}

export default House2