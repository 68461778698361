// Home.js (or your home page component)
import React from 'react';
import Video from './home/Video';
import Hero from './home/Hero';
import PhotoAlbum from './home/PhotoAlbum';
import Contact from './home/Contact';
import Banner from './home/Banner';

function Home() {
  return (
    <div>
      <Video />
      <Banner />
      <PhotoAlbum />
      <Hero />
      <Contact />
    </div>
  );
}

export default Home;
