import React from 'react'
import Album from './Album';

function House4() {

    const imagesHouse4 = [
        'src/assets/house4/drone.jpeg',
        'src/assets/house4/close.jpeg',
        'src/assets/house4/front.jpeg',
        'src/assets/house4/back.jpeg',
        'src/assets/house4/enterance.jpeg',
        'src/assets/house4/dining.jpeg',
        'src/assets/house4/office.jpeg',
        'src/assets/house4/kitchen.jpeg',
        'src/assets/house4/livingroom.jpeg',
        'src/assets/house4/livingroom2.jpeg',
        'src/assets/house4/masterbath.jpeg',
        'src/assets/house4/masterbath2.jpeg',
        'src/assets/house4/masterroom.jpeg',
        'src/assets/house4/closet.jpeg',
        'src/assets/house4/greatroom.jpeg',
        'src/assets/house4/upstairs.jpeg',
        'src/assets/house4/room1.jpeg',
        'src/assets/house4/room2.jpeg',
        'src/assets/house4/balcony.jpeg',
        'src/assets/house4/basement.jpeg',
        'src/assets/house4/porch.jpeg',
        'src/assets/house4/back2.jpeg',
        'src/assets/house4/driveway.jpeg'
      ];   
    
  return (
    <div>
        <Album images={imagesHouse4} />
    </div>
  )
}

export default House4