import React from 'react'
import Album from './Album';

function House6() {

    const imagesHouse6 = [
      'src/assets/house6/front.jpeg',
      'src/assets/house6/enterance.jpeg',
      'src/assets/house6/enterance2.jpeg',
      'src/assets/house6/dining.jpeg',
      'src/assets/house6/living.jpeg',
      'src/assets/house6/living2.jpeg',
      'src/assets/house6/kitchen.jpeg',
      'src/assets/house6/kitchen2.jpeg',
      'src/assets/house6/kitchen3.jpeg',
      'src/assets/house6/breakfast.jpeg',
      'src/assets/house6/bath3.jpeg',
      'src/assets/house6/room.jpeg',
      'src/assets/house6/bath1.jpeg',
      'src/assets/house6/bath2.jpeg'
      ];   
    
  return (
    <div>
        <Album images={imagesHouse6} />
    </div>
  )
}

export default House6