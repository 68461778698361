import React from "react";
import { Container, Typography, Button, Box, Fade } from "@mui/material";
import { Link } from "react-router-dom";

function Banner() {
  return (
    <Fade in={true} timeout={3000}>
      <Box
        className="banner"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: {
            xs: "350px", // small screens
            sm: "500px", // small to medium screens
            md: "700px", // medium to large screens
            lg: "700px", // large screens
            xl: "1000px", // extra-large screens
          },
          p: 4,
          color: "#fff",
          backgroundImage: "linear-gradient(45deg, #113332 30%, #000000 90%)",
        }}
      >
        <iframe
          src="https://www.youtube.com/embed/v6TVi-LhqUw"
          title="YouTube video player"
          frameBorder="1"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen="true"
          style={{
            position: "relative",
            width: "80%",
            height: "100%",
          }}
        />
        <Typography variant="h2" align="center" gutterBottom>
          <Button
            variant="outlined"
            sx={{
              bgcolor: "transparent",
              border: "1px solid #FFA13C",
              borderRadius: "0px",
              color: "#FFA13C",
              ":hover": { bgcolor: "#113332" },
            }}
            component={Link}
            to="/new"
          >
            See Our New Builds
          </Button>
        </Typography>
      </Box>
    </Fade>
  );
}

export default Banner;
