import React from 'react';
import { Container, Typography, Grid, Card, CardContent, CardMedia } from '@mui/material';
import Fade from 'react-reveal/Fade';

function Home() {
  const cardStyle = {
    maxWidth: 500,
    marginBottom: '1.5rem', // Adjust margin as needed
  };

  const mediaStyle = {
    height: '350px', // Adjust image height
  };

  const sectionData = [
    {
        title: 'What Do We Do?',
        imageSrc: 'src/assets/house4/drone.jpeg',
        description: 'Emerich Custom Build specializes in crafting high-end, bespoke homes tailored to the unique preferences of discerning private clients. Our commitment to excellence ensures each home we construct is of the utmost quality, reflecting the individuality and lifestyle of its owner.',
    },
    {
        title: 'Where Are We?',
        imageSrc: 'src/assets/map.jpg',
        description: 'Based in the vibrant Lawrenceville area, Emerich Custom Build extends its expertise to the surrounding regions of Gwinnett County. Our focus is on creating exceptional, personalized homes in these thriving communities, blending local charm with individual client vision.',
    },
    {
      title: 'Who Are We?',
      imageSrc: 'src/assets/stef.jpg',
      description: "Led by our esteemed founder, Stephan, we at Emerich Custom Build are driven by a fervent passion for constructing homes of unparalleled quality. Stephan's dedication to excellence in homebuilding not only shapes our company's ethos but also ensures each project embodies the pinnacle of craftsmanship and design.",
    },
  ];

  return (
    <div>
      <Container maxWidth="md" style={{padding: '20px'}}>
        <Typography variant="h4" gutterBottom style={{ textAlign: 'center', padding: '15px'}}>More About Us</Typography>
        <Grid container spacing={2}>
          {sectionData.map((data, index) => (
            <Grid item xs={12} sm={4} md={4} key={index}>
              <Fade bottom>
                <Card style={cardStyle}>
                  <CardMedia
                    style={mediaStyle}
                    image={data.imageSrc}
                    title={data.title}
                  />
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {data.title}
                    </Typography>
                    <Typography variant="body1" paragraph>
                      {data.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Fade>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
}

export default Home;
