import React, { useState, useEffect } from 'react';
import { Container, Typography, Button } from '@mui/material';
import './PhotoAlbum.css';
import Slide from 'react-reveal/Slide';

function PhotoAlbum({images, buttonText, buttonLink, slideSide}) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [fadeOut, setFadeOut] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsFirstLoad(false);
    }, 1000); // Delay of 1 second
  
    return () => clearTimeout(timer);

    const interval = setInterval(() => {
      setFadeOut(true); // Apply fade-out effect
      setTimeout(() => {
        setActiveIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
        setFadeOut(false); // Remove fade-out effect
      }, 300); // Wait for the transition duration (0.5s) before changing the image
    }, 5000); // Change image every 3 seconds (adjust as needed)

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handlePrev = () => {
    setFadeOut(true); // Apply fade-out effect
    setTimeout(() => {
      setActiveIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
      setFadeOut(false); // Remove fade-out effect
    }, 500); // Wait for the transition duration (0.5s) before changing the image
  };

  const handleNext = () => {
    setFadeOut(true); // Apply fade-out effect
    setTimeout(() => {
      setActiveIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
      setFadeOut(false); // Remove fade-out effect
    }, 500); // Wait for the transition duration (0.5s) before changing the image
  };

  return (
    <div className='general'>
      {isFirstLoad ? (
      <Slide {...{[slideSide]: true}}>
        <Container maxWidth="md" className="photo-album">
          <Typography variant="h5" gutterBottom></Typography>
  
          <div className={`slider-container ${fadeOut ? 'fade-out' : ''}`}>
            <div className="slider-frame">
              <img
                src={images[activeIndex]}
                alt={`Image ${activeIndex + 1}`}
                className={`slider-image ${fadeOut ? 'fade-out' : ''}`}
              />
            </div>
          </div>
  
          <div className='button-container'>
            <Button component="a" href={buttonLink} variant="contained" color="primary">
              {buttonText}
            </Button>
          </div>
        </Container>
      </Slide>
      ) : (
        <Container maxWidth="md" className="photo-album">
          <Typography variant="h5" gutterBottom></Typography>
  
          <div className={`slider-container ${fadeOut ? 'fade-out' : ''}`}>
            <div className="slider-frame">
              <img
                src={images[activeIndex]}
                alt={`Image ${activeIndex + 1}`}
                className={`slider-image ${fadeOut ? 'fade-out' : ''}`}
              />
            </div>
          </div>
  
          <div className='button-container'>
            <Button component="a" href={buttonLink} variant="contained" color="primary">
              {buttonText}
            </Button>
          </div>
        </Container>
      )}
    </div>
  );
}

export default PhotoAlbum;
