import React from 'react'
import Album from './Album';

function House5() {

    const imagesHouse5 = [
        'src/assets/house5/front-wide.jpeg',
        'src/assets/house5/front.jpeg',
        'src/assets/house5/back.jpeg',
        'src/assets/house5/mudroom.jpeg',
        'src/assets/house5/office.jpeg',
        'src/assets/house5/living2.jpeg',
        'src/assets/house5/living1.jpeg',
        'src/assets/house5/kitchen1.jpeg',
        'src/assets/house5/kitchen2.jpeg',
        'src/assets/house5/kitchen3.jpeg',
        'src/assets/house5/breakfast.jpeg',
        'src/assets/house5/dining.jpeg',
        'src/assets/house5/master.jpeg',
        'src/assets/house5/bathroom1.jpeg',
        'src/assets/house5/bathroom2.jpeg',
        'src/assets/house5/bathroom3.jpeg',
        'src/assets/house5/closet1.jpeg',
        'src/assets/house5/cab1.jpeg',
        'src/assets/house5/basement.jpeg',
        'src/assets/house5/patio.jpeg'
      ];   
    
  return (
    <div>
        <Album images={imagesHouse5} />
    </div>
  )
}

export default House5