import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Routes } from "react-router-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Navbar from "./Navbar";
import Footer from "./Footer";
import Home from "./Home";
import About from "./About";
import Portfolio from "./Portfolio";
import Contact from "./Contact";
import New from "./New";

import House1 from "./portfolio/house1";
import House2 from "./portfolio/house2";
import House3 from "./portfolio/house3";
import House4 from "./portfolio/house4";
import House5 from "./portfolio/house5";
import House6 from "./portfolio/house6";
import House7 from "./portfolio/house7";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Router>
        <Navbar />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/new" element={<New />} />

          <Route path="/house1" element={<House1 />} />
          <Route path="/house2" element={<House2 />} />
          <Route path="/house3" element={<House3 />} />
          <Route path="/house4" element={<House4 />} />
          <Route path="/house5" element={<House5 />} />
          <Route path="/house6" element={<House6 />} />
          <Route path="/house7" element={<House7 />} />
        </Routes>

        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
