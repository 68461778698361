import React from 'react'
import Album from './Album';

function House3() {

    const imagesHouse3 = [
        'src/assets/house3/close.jpeg',
        'src/assets/house3/center.jpeg',
        'src/assets/house3/front.jpeg',
        'src/assets/house3/deck.jpeg',
        'src/assets/house3/enterance.jpeg',
        'src/assets/house3/kitchen.jpeg',
        'src/assets/house3/kitchen2.jpeg',
        'src/assets/house3/living.jpeg',
        'src/assets/house3/masterbath.jpeg',
        'src/assets/house3/masterbath2.jpeg',
        'src/assets/house3/closet.jpeg',
        'src/assets/house3/mudroom.jpeg',
        'src/assets/house3/room.jpeg',
        'src/assets/house3/bath1.jpeg',
        'src/assets/house3/room2.jpeg',
        'src/assets/house3/room3.jpeg',
        'src/assets/house3/space.jpeg',
        'src/assets/house3/upstairs.jpeg',
        'src/assets/house3/landscape.jpeg',
        'src/assets/house3/back.jpeg'
      ];
    
  return (
    <div>
        <Album images={imagesHouse3} />
    </div>
  )
}

export default House3