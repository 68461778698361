import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';


function Navbar() {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to control the menu visibility

  // Function to open the menu
  const openMenu = () => {
    setIsMenuOpen(true);
  };

  // Function to close the menu
  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        {/* Add a button to open the menu */}
        {isMobile && (
          <IconButton color="inherit" aria-label="Menu" onClick={openMenu}>
            <MenuIcon />
          </IconButton>
        )}
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Emerich Custom Build
        </Typography>
        {!isMobile ? (
          <>
            <Button color="inherit"><Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>Home</Link></Button>
            <Button color="inherit"><Link to="/new" style={{ textDecoration: 'none', color: 'inherit' }}>New-Builds</Link></Button>
            <Button color="inherit"><Link to="/portfolio" style={{ textDecoration: 'none', color: 'inherit' }}>Projets</Link></Button>
            <Button color="inherit"><Link to="/contact" style={{ textDecoration: 'none', color: 'inherit' }}>Contact</Link></Button>
          </>
        ) : null}
      </Toolbar>
      {/* Add a Drawer component for the menu */}
      <Drawer anchor="left" open={isMenuOpen} onClose={closeMenu}>
        <div
          role="presentation"
          onClick={closeMenu}
          onKeyDown={closeMenu}
          style={{ width: '200px' }} // Adjust the width as needed
        >
          <List>
            <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}><ListItem button><ListItemText primary="Home" /></ListItem></Link>
            <Link to="/new" style={{ textDecoration: 'none', color: 'inherit' }}><ListItem button><ListItemText primary="New Builds" /></ListItem></Link>
            <Link to="/portfolio" style={{ textDecoration: 'none', color: 'inherit' }}><ListItem button><ListItemText primary="Projects" /></ListItem></Link>
            <Link to="/contact" style={{ textDecoration: 'none', color: 'inherit' }}><ListItem button><ListItemText primary="Contact" /></ListItem></Link>
          </List>
        </div>
      </Drawer>
    </AppBar>
  );
}

export default Navbar;
