import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import Slide from 'react-reveal/Slide'; // Import Slide from react-reveal

function Contact() {
  return (
    <div style={{padding: '20px', backgroundColor: '#232729'}}>
      <Slide left>
        <Container maxWidth="md" style={{textAlign: 'center'}}>
          <Typography variant="h4" component="h2" gutterBottom>
            Get Started Today
          </Typography>

          <Typography variant="body1" paragraph>
            If you have questions on the process or would like to discuss the option of building with us, please reach out!
          </Typography>

          <Button
            component={Link}
            to="/contact"
            variant="contained"
            color="primary"
          >
            Get in Touch
          </Button>
        </Container>
      </Slide>
    </div>
  );
}

export default Contact;