import React from 'react';
import { Container, Grid, Typography, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {
  return (
    <footer>
            <div
        style={{
          borderTop: '1px solid #ccc', // Add a top border to create the line
          marginTop: '20px', // Adjust margin as needed
          padding: '0 10px', // Add padding for spacing
          textAlign: 'center',
        }}
      ></div>
      <Container maxWidth="lg" sx={{ py: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" gutterBottom>
              Contact Us
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Lawrenceville, Georgia
              <br />
              Email: stefanemerich@gmail.com
              <br />
              Phone: (770) 231-1541
            </Typography>
          </Grid>
          {/* <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              Social Media
            </Typography>
            <IconButton color="primary" aria-label="Facebook">
              <FacebookIcon />
            </IconButton>
            <IconButton color="primary" aria-label="Twitter">
              <TwitterIcon />
            </IconButton>
            <IconButton color="primary" aria-label="Instagram">
              <InstagramIcon />
            </IconButton>
          </Grid> */}
        </Grid>
      </Container>
      <div
        style={{
          background: '#333',
          color: '#fff',
          padding: '10px 0',
          textAlign: 'center',
        }}
      >
        <Typography variant="body2">
          &copy; {new Date().getFullYear()} Emerich Custom Build LLC. All Rights Reserved.
        </Typography>
      </div>
    </footer>
  );
};

export default Footer;
