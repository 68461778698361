import React from "react";
import Album from "./Album";

function House7() {
  const imagesHouse7 = [
    "src/assets/house7/front.jpg",
    "src/assets/house7/side.jpg",
    "src/assets/house7/house.jpg",
    "src/assets/house7/back.jpg",
    "src/assets/house7/patio.jpg",
    "src/assets/house7/entrance.jpg",
    "src/assets/house7/kitchen.jpg",
    "src/assets/house7/living.jpg",
    "src/assets/house7/kitchen2.jpg",
    "src/assets/house7/kitchen3.jpg",
    "src/assets/house7/mud.jpg",
    "src/assets/house7/mud2.jpg",
    "src/assets/house7/master.jpg",
    "src/assets/house7/mbath.jpg",
    "src/assets/house7/mbath2.jpg",
    "src/assets/house7/mbath3.jpg",
    "src/assets/house7/closet.jpg",
    "src/assets/house7/room1.jpg",
    "src/assets/house7/room2.jpg",
    "src/assets/house7/room3.jpg",
    "src/assets/house7/dining.jpg",
    "src/assets/house7/stairs.jpg",
  ];

  return (
    <div>
      <Album images={imagesHouse7} />
    </div>
  );
}

export default House7;
