import React, { useState } from 'react';
import { Grid, Card, CardMedia, Dialog } from '@mui/material';
import Slide from 'react-reveal/Slide';

function Album({ images }) {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleClickOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    
      <div>
        <Slide left>
        <Grid container spacing={3}>
          {images.map((image, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card>
                <CardMedia
                  component="img"
                  height="140"
                  image={image}
                  alt={`Image ${index + 1}`}
                  onClick={() => handleClickOpen(image)}
                />
              </Card>
            </Grid>
          ))}
        </Grid>

        <Dialog open={open} onClose={handleClose}>
          <img src={selectedImage} alt="Selected" style={{ width: '100%', height: 'auto' }} />
        </Dialog>
        </Slide>
      </div>
    
  );
}

export default Album;