import React, { useState, useEffect } from 'react';
import { Container, Typography, Button } from '@mui/material';
import './PhotoAlbum.css';
import Fade from 'react-reveal/Fade';

const images = [
  'src/assets/house4/front.jpeg',
  'src/assets/house3/close.jpeg',
  'src/assets/house3/masterbath.jpeg',
  'src/assets/house4/drone.jpeg',
  'src/assets/house1/kitchen.jpeg',
  'src/assets/house2/center.jpeg',
  'src/assets/house1/firepit.jpeg'
];

function PhotoAlbum() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [fadeOut, setFadeOut] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);

    const interval = setInterval(() => {
      setFadeOut(true); // Apply fade-out effect
      setTimeout(() => {
        setActiveIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
        setFadeOut(false); // Remove fade-out effect
      }, 300); // Wait for the transition duration (0.5s) before changing the image
    }, 7000); // Change image every 3 seconds (adjust as needed)

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handlePrev = () => {
    setFadeOut(true); // Apply fade-out effect
    setTimeout(() => {
      setActiveIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
      setFadeOut(false); // Remove fade-out effect
    }, 500); // Wait for the transition duration (0.5s) before changing the image
  };

  const handleNext = () => {
    setFadeOut(true); // Apply fade-out effect
    setTimeout(() => {
      setActiveIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
      setFadeOut(false); // Remove fade-out effect
    }, 500); // Wait for the transition duration (0.5s) before changing the image
  };

  return (
    loaded ? (
    <Fade>
    <div className='general'>
    <div style={{borderTop: '2px solid #ccc', padding: '0 10px', textAlign: 'center'}}></div>
    <Container maxWidth="md" className="photo-album">
      <Typography variant="h5" gutterBottom></Typography>

      <div className={`slider-container ${fadeOut ? 'fade-out' : ''}`}>
        <div className="slider-frame">
          <img
            src={images[activeIndex]}
            alt={`Image ${activeIndex + 1}`}
            className={`slider-image ${fadeOut ? 'fade-out' : ''}`}
          />
        </div>
      </div>

      <div className='button-container'>
      <Button component="a" href="/portfolio" variant="contained" color="primary">
        Check Out Our Work
      </Button>
      </div>
      
    </Container>
    <div style={{borderTop: '2px solid #ccc', marginTop: '20px', padding: '0 10px', textAlign: 'center'}}></div>
    </div>
    </Fade>
    ) : null
  );
}

export default PhotoAlbum;
