import React from "react";
import PhotoAlbum from "./portfolio/PhotoAlbum";
import Slide from "react-reveal/Slide";

function New() {
  const imagesHouse1 = [
    "src/assets/house7/front.jpg",
    "src/assets/house7/side.jpg",
    "src/assets/house7/house.jpg",
    "src/assets/house7/back.jpg",
    "src/assets/house7/entrance.jpg",
    "src/assets/house7/kitchen.jpg",
    "src/assets/house7/living.jpg",
  ];

  return (
    <div>
      <PhotoAlbum
        images={imagesHouse1}
        buttonText={"New Home"}
        buttonLink={"/house7"}
        slideSide={"left"}
      />
    </div>
  );
}

export default New;
