import React from 'react';
import { Container, Typography } from '@mui/material';
import './Video.css';

function Video() {
  return (
    <div>
    <Container maxWidth={false} style={{ paddingLeft: 0, paddingRight: 0, position: 'relative' }}>
      <video autoPlay loop muted playsInline src="src/assets/houses.mov" width="100%" height="auto" style={{ display: 'block', filter: 'brightness(0.7) blur(5px)'}}/>
      <div style={{ position: 'absolute', top: '50%',  left: '40%', transform: 'translate(-50%, -50%)',  color: 'white', padding: '0px', alignContent: 'center'}}>
        {/* <span style={{border: '3px solid #ccc', padding: '20px', display: 'inline-block'}}>Emerich Custom Build</span> */}
        <img src="src/assets/logo.PNG" alt="Logo" style={{ display: 'inline-block', maxWidth: '300%', height: 'auto', '@media (max-width: 800px)': {width: '300%'}, objectFit: 'contain', width: '130%'}}/>        
      </div>
    </Container>
    </div>
  );
}

export default Video;

