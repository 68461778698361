import React from 'react'
import PhotoAlbum from './portfolio/PhotoAlbum'
import Slide from 'react-reveal/Slide';

function Portfolio() {

  const imagesHouse1 = [
    'src/assets/house1/front.jpg',
    'src/assets/house1/side.jpeg',
    'src/assets/house1/house.jpg',
    'src/assets/house1/back.jpeg',
    'src/assets/house1/entrance.jpeg',
    'src/assets/house1/kitchen.jpeg',
    'src/assets/house1/living.jpeg',
    'src/assets/house1/gazibo.jpeg',
    'src/assets/house1/porch.jpeg',
    'src/assets/house1/firepit.jpeg',
  ];

  const imagesHouse2 = [
    'src/assets/house2/front.jpeg',
    'src/assets/house2/center.jpeg',
    'src/assets/house2/kitchen.jpeg',
    'src/assets/house2/room.jpeg',
    'src/assets/house2/back.jpeg',
    'src/assets/house2/outside.jpeg',
  ];
  
  const imagesHouse3 = [
    'src/assets/house3/close.jpeg',
    'src/assets/house3/center.jpeg',
    'src/assets/house3/front.jpeg',
    'src/assets/house3/deck.jpeg',
    'src/assets/house4/enterance.jpeg',
    'src/assets/house3/kitchen.jpeg',
    'src/assets/house3/living.jpeg',
    'src/assets/house3/masterbath.jpeg',
    'src/assets/house3/landscape.jpeg',
    'src/assets/house3/back.jpeg'
  ];

  const imagesHouse4 = [
    'src/assets/house4/drone.jpeg',
    'src/assets/house4/close.jpeg',
    'src/assets/house4/front.jpeg',
    'src/assets/house4/entrance.jpeg',
    'src/assets/house4/dining.jpeg',
    'src/assets/house4/office.jpeg',
    'src/assets/house4/kitchen.jpeg',
    'src/assets/house4/livingroom.jpeg',
    'src/assets/house4/masterbath.jpeg',
    'src/assets/house4/porch.jpeg',
    'src/assets/house4/back2.jpeg',
  ];  

  const imagesHouse5 = [
    'src/assets/house5/front-wide.jpeg',
    'src/assets/house5/mudroom.jpeg',
    'src/assets/house5/office.jpeg',
    'src/assets/house5/living1.jpeg',
    'src/assets/house5/kitchen1.jpeg',
    'src/assets/house5/breakfast.jpeg',
    'src/assets/house5/dining.jpeg',
    'src/assets/house5/bathroom1.jpeg',
    'src/assets/house5/closet1.jpeg',
    'src/assets/house5/basement.jpeg',
  ]

  const imagesHouse6 = [
    'src/assets/house6/front.jpeg',
    'src/assets/house6/enterance.jpeg',
    'src/assets/house6/enterance2.jpeg',
    'src/assets/house6/dining.jpeg',
    'src/assets/house6/living.jpeg',
    'src/assets/house6/kitchen.jpeg'
  ]

  return (
    <div>
      <PhotoAlbum images={imagesHouse4} buttonText={"Renovated Two-Story Colonial Home"} buttonLink={"/house4"} slideSide={"left"}/>
      <PhotoAlbum images={imagesHouse3} buttonText={"Modern Two-Story Cottage Home"} buttonLink={"/house3"} slideSide={"right"}/>
      <PhotoAlbum images={imagesHouse1} buttonText={"Bachelor's Mediterranean Ranch"} buttonLink={"/house1"} slideSide={"left"}/>
      <PhotoAlbum images={imagesHouse2} buttonText={"Contemporary Single-Story Home"} buttonLink={"/house2"} slideSide={"right"}/>
      <PhotoAlbum images={imagesHouse5} buttonText={"European Luxury"} buttonLink={"/house5"} slideSide={"left"}/>
      <PhotoAlbum images={imagesHouse6} buttonText={"Modern Transitional Two-Story Home"} buttonLink={"/house6"} slideSide={"right"}/>
    </div>
  )
}

export default Portfolio