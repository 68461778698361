import React from 'react'
import Album from './Album';
import Slide from 'react-reveal/Slide';

function House1() {

    const imagesHouse1 = [
        'src/assets/house1/front.jpg',
        'src/assets/house1/side.jpeg',
        'src/assets/house1/house.jpg',
        'src/assets/house1/back.jpeg',
        'src/assets/house1/entrance.jpeg',
        'src/assets/house1/kitchen.jpeg',
        'src/assets/house1/living.jpeg',
        'src/assets/house1/living2.jpeg',
        'src/assets/house1/masterbed.jpeg',
        'src/assets/house1/masterbath1.jpeg',
        'src/assets/house1/masterbath2.jpeg',
        'src/assets/house1/kidbed1.jpeg',
        'src/assets/house1/kidbed2.jpeg',
        'src/assets/house1/basement.jpeg',
        'src/assets/house1/basementmore.jpeg',
        'src/assets/house1/basementroom.jpeg',
        'src/assets/house1/musicroom.jpeg',
        'src/assets/house1/gazibo.jpeg',
        'src/assets/house1/porch.jpeg',
        'src/assets/house1/firepit.jpeg',
      ];

      return (
        <div>
          <Album images={imagesHouse1} />
        </div>
      );
}

export default House1